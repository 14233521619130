import { z } from 'zod'

export const ImageResource = z.object({
  id: z.number(),
  // location_id: z.number(),
  path: z.string(),
  description: z.string().nullable(),
  // created_at: z.string(),
  // updated_at: z.string(),
})

export type ImageResource = z.infer<typeof ImageResource>