import {z} from 'zod'

export const AvailabilityResource = z.object({
  full_day: z.boolean(),
  morning: z.boolean(),
  morning_double: z.boolean(),
  evening: z.boolean(),
  evening_double: z.boolean(),
})

export type AvailabilityResource = z.infer<typeof AvailabilityResource>